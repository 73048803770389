import './Loading.scss';

const Loading: React.FC = () => {
  return (
    <div className="fixed top-0 left-0 w-full max-w-[374px] h-screen flex items-center justify-center z-50 ">
      <div className="absolute w-full h-full bg-[rgba(17,24,39,0.7)] z-[-1]" />
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loading;
