import { forwardRef, useEffect, useState } from 'react';
import {
  Field,
  Label,
  Input as InputComponent,
  InputProps,
} from '@headlessui/react';
import { AnimatePresence } from 'framer-motion';
import cx from 'classnames';

import Fade from 'components/ui/Fade';
import Tooltip from 'components/ui/Tooltip';
import Button from 'components/ui/Button';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = InputProps & {
  className?: string;
  label: string;
  initialValue?: string | number | null;
  tooltip?: string;
  hint?: string;
  btnText: string;
  btnHint?: string | React.ReactNode;
  showBtn: boolean;
  invalid?: boolean;
  invalidMsg?: string;
  validator?: (value: string | number) => boolean;
  onChange: (value: string | number) => any;
  onClick: (value: string | number) => any;
  extraContent?: React.ReactNode;
};

const formatNumber = (number: number | null | undefined) => {
  if (!number && number !== 0) {
    return '';
  }

  let result = new Intl.NumberFormat('de-ch', {
    maximumFractionDigits: 2,
  }).format(number);

  return result;
};

const inputCls =
  'block w-full px-2 py-[11px] placeholder:text-border border border-border rounded focus:outline-none focus:ring-2 focus:ring-primary transition-colors';
const invalidCls = 'border-danger border-[1.5px]';

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      initialValue,
      tooltip,
      hint,
      btnText,
      btnHint,
      onChange,
      onClick,
      showBtn,
      invalid: invalidProp = false,
      invalidMsg,
      validator,
      extraContent,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(
      initialValue
        ? typeof initialValue === 'number'
          ? formatNumber(initialValue)
          : initialValue
        : ''
    );
    const [invalid, setInvalid] = useState(invalidProp);

    useEffect(() => {
      setInvalid(invalidProp);
    }, [invalidProp]);

    const parseNumber = (val: string) => parseInt(val.replace(/’/g, ''), 10);

    const handleNumericChange = (e: any) => {
      const value = parseNumber(e.target.value);
      const formattedValue = formatNumber(value);
      setValue(formattedValue);
    };

    const handleOnClick = () => {
      const val = props.type === 'number' ? parseNumber(value) : value;

      if (!validator || validator(val)) {
        onClick(val);
      } else if (!invalidProp) {
        setInvalid(true);
      }
    };

    const input =
      props.type === 'number' ? (
        <InputComponent
          ref={ref}
          className={cx(inputCls, invalid && invalidCls)}
          {...props}
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            if (!invalidProp) {
              setInvalid(false);
            }
            handleNumericChange(e);
          }}
          onKeyDown={(e) => (e.key === 'Enter' ? handleOnClick() : {})}
        />
      ) : (
        <InputComponent
          ref={ref}
          className={cx(inputCls, invalid && invalidCls)}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            if (!invalidProp) {
              setInvalid(false);
            }
            setValue(e.target.value);
          }}
          onKeyDown={(e) => (e.key === 'Enter' ? handleOnClick() : {})}
          {...props}
        />
      );

    return (
      <div className={className}>
        <Field>
          <Label className="flex items-center font-bold mb-0.5">
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoIcon className="ml-1 size-5 cursor-pointer" />
              </Tooltip>
            )}
          </Label>
          {input}
          <AnimatePresence>
            {invalid && invalidMsg && (
              <Fade exit={false}>
                <p className="mt-0.5 text-sm text-danger">{invalidMsg}</p>
              </Fade>
            )}
          </AnimatePresence>
          {hint && <p className="mt-0.5 text-sm text-border">{hint}</p>}
        </Field>
        {extraContent}
        {showBtn && (
          <Button
            className="mt-6 w-full"
            label={btnText}
            isDisabled={!value || invalid}
            onClick={handleOnClick}
          />
        )}
        {btnHint && (
          <p className="mt-6 text-xs html-md text-center">{btnHint}</p>
        )}
      </div>
    );
  }
);

export default Input;
