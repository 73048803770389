import Button from 'components/ui/Button';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export type Props = {
  title: string;
  description: string | React.ReactNode;
  btnText: string;
  onDismiss: () => any;
  onConfirm: () => any;
};

const Modal: React.FC<Props> = ({
  title,
  description,
  btnText,
  onConfirm,
  onDismiss,
}) => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-full max-w-[374px] h-screen z-50">
      <div className="absolute w-full h-full bg-[rgba(17,24,39,0.7)] z-[-1]" />
      <div className="w-full mx-4 bg-background rounded-lg">
        <div className="flex items-center justify-between px-4 py-5">
          <h2 className="text-xl font-bold">{title}</h2>
          <CloseIcon
            className="shrink-0 ml-3 size-6 cursor-pointer"
            onClick={onDismiss}
          />
        </div>
        <p className="p-6 border-t border-b border-border-light text-sm max-h-[500px] html-md whitespace-pre-line overflow-y-auto">
          {description}
        </p>
        <div className="px-4 py-3">
          <Button
            className="w-full"
            label={btnText}
            withIcon={false}
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
