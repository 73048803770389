import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { getCmsData, getMakes } from 'api/api';
import { camelizeObject } from 'utils/utils';

import type { Locale, Option } from 'types/types';

import { LS_CMS_DATA_CACHE, LS_MAKES_DATA_CACHE } from 'constants/constants';

type DataSection =
  | 'directsaleTextStrings'
  | 'generalTextStrings'
  | 'formFields';

export type Translations = {
  id: string;
  locale: Locale;
  data: ({ [key: string]: any } & { enable2fa: boolean }) | null;
  isLoading: boolean;
  t: (section: DataSection, key: string, subKey?: string) => string;
  getFormFieldType: (field: string) => string;
  getGeneralOptions: (field: string) => Option[];
};

const defaultValue: Translations = {
  id: '',
  locale: 'de',
  data: localStorage.getItem(LS_CMS_DATA_CACHE)
    ? JSON.parse(localStorage.getItem(LS_CMS_DATA_CACHE)!)
    : null,
  isLoading: true,
  t: () => '__empty__',
  getFormFieldType: () => 'text',
  getGeneralOptions: () => [],
};

const CmsDataContext = createContext(defaultValue);

export const useCmsData = () => useContext(CmsDataContext);

export const CmsDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [id] = useState('as24');
  const [locale, setLocale] = useState(defaultValue.locale);
  const [data, setData] = useState<Translations['data']>(defaultValue.data);

  const { isLoading: isLoadingCmsData } = useQuery(
    'cmsData',
    () => getCmsData(id),
    {
      onSuccess: (fullCmsData: any) => {
        const cmsData: { [key: string]: any } = camelizeObject(
          _.pick(fullCmsData, [
            'directsale_text_strings',
            'general_text_strings',
            'form_fields',
            'Settings',
          ])
        );

        setData({ ...cmsData, enable2fa: !!fullCmsData.enable_2fa });
        localStorage.setItem(LS_CMS_DATA_CACHE, JSON.stringify(cmsData));
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  const { isLoading: isLoadingMakes } = useQuery('makes', () => getMakes(), {
    onSuccess: (makes: any) => {
      localStorage.setItem(LS_MAKES_DATA_CACHE, JSON.stringify(makes));
    },
    onError: () =>
      alert(
        'Unfortunately something went wrong! Please try again later or contact us.'
      ),
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');

    if (['de', 'en', 'fr', 'it'].indexOf(lang || '') !== -1) {
      setLocale(lang as Locale);
    }
  }, []);

  const t = (section: DataSection, key: string, subKey?: string) => {
    if (!data) {
      return '__empty__';
    }

    if (section === 'formFields' && subKey) {
      const translations = _.find(data?.[section], { key })?.translation;

      if (!translations) {
        return '__empty__';
      }

      return _.find(translations, { language: locale })?.[subKey];
    }

    return _.find(data?.[section], { key })?.[locale] || '__empty__';
  };

  const getFormFieldType = (key: string) => {
    const type = _.find(data?.formFields, { key })?.type;

    return type;
  };

  const getGeneralOptions = (key: string) => {
    const options = [];

    if (data) {
      for (let i = 1; i <= 4; i++) {
        const option = data.generalTextStrings.find(
          (el: any) => el.key === `${key}${i}`
        )[locale];
        options.push({ name: option, value: '' + i });
      }
    }

    return options;
  };

  return (
    <CmsDataContext.Provider
      value={{
        id,
        locale,
        data,
        t,
        getFormFieldType,
        getGeneralOptions,
        isLoading:
          (isLoadingCmsData && !data) ||
          (isLoadingMakes && !localStorage.getItem(LS_MAKES_DATA_CACHE)),
      }}
    >
      {children}
    </CmsDataContext.Provider>
  );
};

export default CmsDataProvider;
