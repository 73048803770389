import { numberWithSeparator } from 'utils/utils';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = {
  min: number;
  max: number;
  hint: string;
  onHintClick: () => any;
};

const PriceRange: React.FC<Props> = ({ min, max, hint, onHintClick }) => {
  return (
    <div className="p-6 rounded-2xl border border-highlight bg-highlight-tint">
      <div className="mb-2.5 text-center text-xl font-bold leading-[30px]">
        CHF {numberWithSeparator(min)} - CHF {numberWithSeparator(max)}
      </div>
      <div className="relative flex items-center justify-center">
        <div className="flex items-center justify-between w-[200px] h-6 rounded-full bg-gradient-to-r from-secondary-shade to-secondary-tint z-[2]" />
        <div className="absolute top-0 left-0 bg-[linear-gradient(90deg,transparent,var(--color-secondary-tint)_32%,var(--color-secondary-shade)_68%,transparent)] opacity-[0.24] w-full h-6 z-[1]" />
      </div>
      <div
        className="mt-3 flex text-tertiary text-sm cursor-pointer"
        onClick={onHintClick}
      >
        <InfoIcon className="shrink-0 size-6 mr-2" />
        {hint}
      </div>
    </div>
  );
};

export default PriceRange;
