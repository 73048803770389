import cx from 'classnames';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

type Props = {
  href?: string;
  className?: string;
  label: string;
  onClick?: () => any;
  withIcon?: boolean;
  isDisabled?: boolean;
};

const Button: React.FC<Props> = ({
  href,
  className,
  label,
  onClick,
  withIcon = true,
  isDisabled = false,
}) => {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(
          'flex items-center justify-center px-4 py-3 font-bold bg-primary border-b-2 border-primary-shade rounded transition-opacity ease-in',
          isDisabled && 'opacity-70 cursor-not-allowed',
          className
        )}
      >
        {label}
        {withIcon && <ArrowRightIcon className="ml-1 size-6" />}
      </a>
    );
  }

  return (
    <button
      className={cx(
        'flex items-center justify-center px-4 py-3 font-bold bg-primary border-b-2 border-primary-shade rounded transition-opacity ease-in',
        isDisabled &&
          '!text-border !bg-border-light !border-none cursor-not-allowed',
        className
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      {label}
      {withIcon && <ArrowRightIcon className="ml-1 size-6" />}
    </button>
  );
};

export default Button;
