export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const LS_CMS_DATA_CACHE = 'wcc';
export const LS_MAKES_DATA_CACHE = 'wmc';

export const MAKES = {
  120: 'BMW',
  125: 'BMW Alpina',
  140: 'Buick',
  160: 'Cadillac',
  165: 'Caterham',
  180: 'Chevrolet',
  200: 'Chrysler',
  201: 'Chrysler USA',
  220: 'Citroën',
  227: 'CUPRA',
  230: 'Dacia',
  231: 'Daewoo',
  240: 'Daihatsu',
  241: 'Daimler',
  242: 'Dallas',
  243: 'De Tomaso',
  246: 'Dodge',
  249: 'Dodge USA',
  252: 'DS Automobiles',
  255: 'Ferrari',
  260: 'Fiat',
  263: 'Fisker',
  280: 'Ford',
  300: 'Ford USA',
  320: 'Honda',
  336: 'Hummer',
  340: 'Hyundai',
  350: 'Infiniti',
  360: 'Innocenti',
  380: 'Isuzu',
  410: 'JAC',
  420: 'Jaguar',
  440: 'Jeep',
  451: 'Kia',
  460: 'Lada',
  470: 'Lamborghini',
  480: 'Lancia',
  482: 'Land Rover',
  500: 'Lexus',
  510: 'Lotus',
  520: 'Maserati',
  540: 'Mazda',
  558: 'Mega',
  560: 'Mercedes-Benz',
  570: 'MG',
  615: 'Mini',
  620: 'Mitsubishi',
  625: 'Morgan',
  640: 'Nissan',
  660: 'Oldsmobile',
  680: 'Opel',
  700: 'Peugeot',
  716: 'Polestar',
  720: 'Pontiac',
  740: 'Porsche',
  760: 'Puch',
  780: 'Renault',
  790: 'Rolls Royce',
  800: 'Rover',
  820: 'Saab',
  822: 'Saab-Hirsch',
  840: 'SEAT',
  860: 'Skoda',
  862: 'Smart',
  864: 'SsangYong',
  880: 'Subaru',
  881: 'Subaru USA',
  900: 'Suzuki',
  920: 'Talbot',
  924: 'Tata',
  930: 'Tesla',
  940: 'Toyota',
  947: 'TVR',
  960: 'Volvo',
  980: 'VW',
  982: 'Westfield',
  '010': 'Aixam',
  '020': 'Alfa Romeo',
  '022': 'Alpine',
  '040': 'AMC',
  '055': 'Aston Martin',
  '060': 'Audi',
  '080': 'Austin',
  '090': 'Bentley',
};
