import { motion } from 'framer-motion';
import cx from 'classnames';

type Props = {
  className?: string;
  activeSide?: ActiveSide;
  leftText: string;
  rightText: string;
  onLeftSwitch: () => any;
  onRightSwitch: () => any;
};

export enum ActiveSide {
  Left,
  Right,
}

const SwitchButton: React.FC<Props> = ({
  className,
  activeSide,
  leftText,
  rightText,
  onLeftSwitch,
  onRightSwitch,
}) => {
  const onSwitch = (handler: Props['onLeftSwitch' | 'onRightSwitch']) => {
    handler();

    activeSide === ActiveSide.Left ? onRightSwitch() : onLeftSwitch();
  };

  const bgSpanElement = (
    <motion.span
      layoutId="bubble"
      className="absolute inset-0 z-[-1] bg-dark h-[100px]"
      transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
    />
  );

  const btnCls = 'relative px-2 py-[11px] text-center';
  const btnTextCls = (active: boolean) =>
    cx(active && 'text-background transition-colors duration-300');

  return (
    <div
      className={cx(
        'grid grid-cols-2 border border-dark rounded font-bold cursor-pointer overflow-hidden',
        className
      )}
    >
      <div
        className={btnCls}
        onClick={() =>
          activeSide !== ActiveSide.Left ? onSwitch(onLeftSwitch) : {}
        }
      >
        <span
          className={cx('delay-75', btnTextCls(activeSide === ActiveSide.Left))}
        >
          {leftText}
        </span>
        {activeSide === ActiveSide.Left && bgSpanElement}
      </div>
      <div
        className={btnCls}
        onClick={() =>
          activeSide !== ActiveSide.Right ? onSwitch(onRightSwitch) : {}
        }
      >
        <span className={btnTextCls(activeSide === ActiveSide.Right)}>
          {rightText}
        </span>
        {activeSide === ActiveSide.Right && bgSpanElement}
      </div>
    </div>
  );
};

export default SwitchButton;
