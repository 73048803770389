import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Refiner from 'refiner-js';
import { AnimatePresence } from 'framer-motion';
import validator from 'validator';
import parse from 'html-react-parser';
import _ from 'lodash';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Fade from 'components/ui/Fade';
import SwitchButton, { ActiveSide } from 'components/ui/SwitchButton';
import Select from 'components/ui/Select';
import Loading from 'components/Loading/Loading';
import Modal, { Props as ModalProps } from 'components/ui/Modal';
import ResultPane from 'components/ui/ResultPane';
import Input from 'components/ui/Input';
import Headline from 'components/ui/Headline';
import PhoneVerification from 'components/PhoneVerification';
import FinalView from 'components/FinalView';

import { useCmsData, Translations } from 'contexts/CmsDataProvider';
import {
  getAttributes,
  getMakes,
  getModels,
  getVehicleByLicensePlate,
  getVehicles,
  makeSubmission,
  sendOtpCode,
  verifyPhoneNumber,
} from 'api/api';
import { IS_PRODUCTION, LS_MAKES_DATA_CACHE, MAKES } from 'constants/constants';
import {
  focusNextField,
  formatPhoneNumber,
  formatRelevantVehicles,
  getOptionsFromAttributes,
  getRelevantOptions,
  parseUniqueByField,
} from 'utils/utils';
import { trackEvent } from 'services/plausible';
import { Stage } from 'components/StatusLine';

import type { UserInput, VehicleResults } from 'types/types';

type Props = {
  onSetStage: (stage: Stage) => any;
};

export enum Mode {
  LicensePlate,
  Brand,
}

enum Step {
  LicensePlate,
  Make,
  Model,
  Year,
  BodyType,
  Fuel,
  Gear,
  Type,
  ArticleKey,
  Mileage,
  Condition,
  Equipment,
  Email,
  FirstName,
  LastName,
  Phone,
  PhoneVerification,
  FinalView,
}

const yearOptions = _.range(new Date().getFullYear(), 1969, -1).map((year) => ({
  name: year.toString(),
  value: year,
}));

const INITIAL_DATA: UserInput = {
  make: null,
  model: null,
  year: null,
  bodyType: null,
  fuel: null,
  gear: null,
  type: null,
  articleKey: null,
  mileage: null,
  condition: null,
  equipment: null,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  token: null,
};

const orderedFields = [
  ..._.filter(
    _.map(
      _.keys(Step).filter((key) => !isNaN(Number(key))),
      (key: any) => _.camelCase(Step[key])
    ),
    (key) => _.has(INITIAL_DATA, key)
  ),
  ..._.difference(
    _.keys(INITIAL_DATA),
    _.filter(
      _.map(
        _.keys(Step).filter((key) => !isNaN(Number(key))),
        (key: any) => _.camelCase(Step[key])
      ),
      (key) => _.has(INITIAL_DATA, key)
    )
  ),
];

const INITIAL_VEHICLE_RESULTS: VehicleResults = {
  all: [],
  bodyTypes: [],
  fuels: [],
  gears: [],
  types: [],
};

const INITAL_MODAL_DATA = {
  isOpen: false,
  title: '',
  description: '',
  btnText: '',
  onConfirm: () => {},
};
const NO_VEHICLES_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('generalTextStrings', 'error_no_vehicle_modal_title'),
  description: t('generalTextStrings', 'error_no_vehicle_modal_description'),
  btnText: t('generalTextStrings', 'error_no_vehicle_modal_button'),
  onConfirm: () => {},
});
const NO_LICENSE_PLATE_RESULTS_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('generalTextStrings', 'error_no_license_plate_number_modal_title'),
  description: t(
    'generalTextStrings',
    'error_no_license_plate_number_modal_description'
  ),
  btnText: t(
    'generalTextStrings',
    'error_no_license_plate_number_modal_button'
  ),
  onConfirm: () => {},
});
const ESTIMATION_PRICE_DESC_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('directsaleTextStrings', 'estimation_price_desc_modal_title'),
  description: parse(
    t('directsaleTextStrings', 'estimation_price_desc_modal_description')
  ),
  btnText: t('directsaleTextStrings', 'estimation_price_desc_modal_button'),
  onConfirm: () => {},
});

const NO_ESTIMATION_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('directsaleTextStrings', 'no_estimation_modal_title'),
  description: (
    <ReactMarkdown
      className="markdown"
      rehypePlugins={[rehypeRaw]}
      children={t('directsaleTextStrings', 'no_estimation_modal_description')}
    />
  ),
  btnText: t('directsaleTextStrings', 'no_estimation_modal_button'),
  onConfirm: () => {},
});

const selectCls = 'mb-6 last:mb-[240px]';

function scrollToBottom() {
  document
    .getElementById('container')
    ?.scrollTo({ top: 9999, behavior: 'smooth' });
}

const Main: React.FC<Props> = ({ onSetStage }) => {
  const [mode, setMode] = useState<Mode>(Mode.LicensePlate);
  const [userInput, setUserInput] = useState<UserInput>(INITIAL_DATA);
  const [vehicleResults, setVehicleResults] = useState(INITIAL_VEHICLE_RESULTS);
  const [selectedVehicle, setSelectedVehicle] = useState<
    VehicleResults['all'][number] | null
  >(null);
  const [estimationPrice, setEstimatedPrice] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const {
    make,
    model,
    year,
    bodyType,
    fuel,
    gear,
    type,
    articleKey,
    mileage,
    condition,
    equipment,
    email,
    firstName,
    lastName,
    phone,
    token,
  } = userInput;
  const [step, setStep] = useState<Step>(
    mode === Mode.LicensePlate ? Step.LicensePlate : Step.Make
  );
  const [modal, setModal] = useState<
    { isOpen: boolean } & Omit<ModalProps, 'onDismiss'>
  >(INITAL_MODAL_DATA);
  const [submissionLink, setSubmissionLink] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const {
    id: widgetId,
    data: cmsData,
    t,
    locale,
    getFormFieldType,
    getGeneralOptions,
  } = useCmsData();

  const prevStepRef = useRef(step);
  const modelRef = useRef(null);
  const yearRef = useRef(null);
  const bodyTypeRef = useRef(null);
  const fuelRef = useRef(null);
  const gearRef = useRef(null);
  const typeRef = useRef(null);
  const articleKeyRef = useRef(null);
  const mileageRef = useRef(null);
  const conditionRef = useRef(null);
  const equipmentRef = useRef(null);
  const emailRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneRef = useRef(null);
  const otpCodeRef = useRef(null);

  useEffect(() => {
    setStep(mode === Mode.LicensePlate ? Step.LicensePlate : Step.Make);
  }, [mode]);

  useEffect(() => {
    if (step === Step.FinalView) {
      document
        .getElementById('container')
        ?.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (step > Step.Model) {
      if (prevStepRef.current !== Step.FinalView) {
        onFocusNextField(step);
      } else {
        document
          .getElementById('container')
          ?.scrollTo({ top: 9999, behavior: 'smooth' });
      }
    }

    if (step < Step.Mileage) {
      onSetStage(Stage.Search);
    } else if (step >= Step.Mileage && step < Step.Email) {
      onSetStage(Stage.Condition);
    } else if (step === Step.FinalView) {
      onSetStage(Stage.Completed);
    } else {
      onSetStage(Stage.Offer);
    }

    prevStepRef.current = step;
  }, [step]); // eslint-disable-line

  const onFocusNextField = (step: number) => {
    switch (step) {
      case Step.Model:
        return focusNextField(modelRef);
      case Step.Year: {
        return focusNextField(yearRef);
      }
      case Step.BodyType: {
        return focusNextField(bodyTypeRef);
      }
      case Step.Fuel: {
        return focusNextField(fuelRef);
      }
      case Step.Gear: {
        return focusNextField(gearRef);
      }
      case Step.Type: {
        return focusNextField(typeRef);
      }
      case Step.ArticleKey: {
        return focusNextField(articleKeyRef);
      }
      case Step.Mileage: {
        focusNextField(mileageRef, 'input');
        return setTimeout(scrollToBottom, 350);
      }
      case Step.Condition: {
        return focusNextField(conditionRef);
      }
      case Step.Equipment: {
        return focusNextField(equipmentRef);
      }
      case Step.Email: {
        return focusNextField(emailRef, 'input');
      }
      case Step.FirstName: {
        return focusNextField(firstNameRef, 'input');
      }
      case Step.LastName: {
        return focusNextField(lastNameRef, 'input');
      }
      case Step.Phone: {
        focusNextField(phoneRef, 'input');
        return setTimeout(scrollToBottom, 50);
      }
      case Step.PhoneVerification: {
        focusNextField(otpCodeRef, 'input');
        return setTimeout(scrollToBottom, 50);
      }
      default:
        break;
    }
  };

  const {
    data: makes = localStorage.getItem(LS_MAKES_DATA_CACHE)
      ? JSON.parse(localStorage.getItem(LS_MAKES_DATA_CACHE)!)
      : [],
  } = useQuery('makes', () => getMakes(), {
    enabled: !queryClient.getQueryData('makes'),
    onError: () =>
      alert(
        'Unfortunately something went wrong! Please try again later or contact us.'
      ),
  });
  const { data: models = [], isLoading: isLoadingModels } = useQuery(
    ['models', make],
    () => getModels(make),
    {
      enabled: !!make && !queryClient.getQueryData(['models', make]),
      onSuccess: () => focusNextField(modelRef),
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );
  const { isLoading: isLoadingAttributes, data: attributes } = useQuery(
    'attributes',
    () => getAttributes(),
    {
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );
  const { mutate: onGetVehicles, isLoading: isLoadingVehicles } = useMutation(
    'vehicles',
    ({ make, model, year }: Pick<UserInput, 'make' | 'model' | 'year'>) =>
      getVehicles({
        make,
        model,
        year,
      }),
    {
      retry: 3,
      onSuccess: (data: any) => {
        if (!data || !data.length) {
          return setModal({
            ...NO_VEHICLES_MODAL(t),
            onConfirm: () => setModal((prev) => ({ ...prev, isOpen: false })),
          });
        }

        setVehicleResults({
          all: data,
          bodyTypes: parseUniqueByField(data, 'bodyType'),
          fuels: parseUniqueByField(data, 'fuel'),
          gears: parseUniqueByField(data, 'gear'),
          types: parseUniqueByField(data, 'type'),
        });
        setStep(Step.BodyType);
      },
      onError: () => {
        setStep(Step.Year);
        setVehicleResults(INITIAL_VEHICLE_RESULTS);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      },
    }
  );
  const {
    mutate: onGetVehicleByLicensePlate,
    isLoading: isLoadingGetVehicleByLicensePlate,
  } = useMutation(
    'getVehicleByLicensePlate',
    (licensePlate: string) => getVehicleByLicensePlate(licensePlate),
    {
      retry: 3,
      onSuccess: (data) => {
        if (!data || !data.length || !data[0].bodyType) {
          throw Error();
        }

        setVehicleResults((prev) => ({ ...prev, all: data }));

        if (data.length > 1) {
          return setStep(Step.ArticleKey);
        }

        onChangeResultPane(data[0].articleKey, data);
      },
      onError: () =>
        setModal({
          ...NO_LICENSE_PLATE_RESULTS_MODAL(t),
          onConfirm: () => {
            setModal((prev) => ({ ...prev, isOpen: false }));
            setMode(
              mode === Mode.LicensePlate ? Mode.Brand : Mode.LicensePlate
            );
          },
        }),
    }
  );
  const { mutateAsync: onSendOtpCode, isLoading: isLoadingSendOtpCode } =
    useMutation('sendOtp', (phone: UserInput['phone']) => sendOtpCode(phone), {
      onSuccess: () => setStep(Step.PhoneVerification),
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    });
  const {
    mutateAsync: onVerifyPhoneNumber,
    isLoading: isLoadingVerifyPhoneNumber,
  } = useMutation('verifyOtp', (code: string) =>
    verifyPhoneNumber({ phone, code })
  );
  const { mutateAsync: onMakeSubmission, isLoading: isLoadingMakeSubmission } =
    useMutation('makeSubmission', (data: any) => makeSubmission(data));

  const handleChangeUserInput = (field: keyof UserInput, value: any) => {
    const fieldIndex = orderedFields.indexOf(field as string);
    const updatedData = { ...userInput };

    orderedFields.forEach((key, index) => {
      if (index >= fieldIndex) {
        updatedData[key as keyof UserInput] =
          index === fieldIndex ? value : INITIAL_DATA[key as keyof UserInput];
      }
    });

    setUserInput(updatedData);
  };

  const onSubmit = async ({
    userPhone = '',
    verificationToken = '',
  }: {
    userPhone?: UserInput['phone'];
    verificationToken?: UserInput['token'];
  }) => {
    try {
      const data: any = {
        widget_key: widgetId,
        token: verificationToken || token,
        type: 'sell',
        sell_option: 'fixprice',
        vehicle: {
          make:
            make || _.findKey(MAKES, (val) => val === selectedVehicle?.brand),
          model: model || selectedVehicle?.model,
          first_registration_year:
            year || selectedVehicle?.firstRegistrationDate.split('.').pop(),
          first_registration_month:
            Number.parseInt(
              selectedVehicle?.firstRegistrationDate.split('.')[1] || '1'
            ) - 1,
          body: bodyType || selectedVehicle?.bodyType,
          fuel: fuel || selectedVehicle?.fuel,
          gear: gear || selectedVehicle?.gear,
          exact_model: type || selectedVehicle?.type,
          article_key: articleKey,
          hp: selectedVehicle?.hp,
          kw: selectedVehicle?.kw
            ? selectedVehicle?.kw
            : selectedVehicle?.hp
            ? Math.round(0.7457 * selectedVehicle.hp)
            : undefined,
          mileage,
          condition,
          equipment,
          displacement: selectedVehicle?.displacement,
          number_of_doors: selectedVehicle?.doors,
          number_of_seats: selectedVehicle?.seats,
          wheel_drive: selectedVehicle?.wheelDrive,
        },
        contact: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone: userPhone || phone,
          language: locale,
        },
        type_number: selectedVehicle?.typeNumber,
        price_from: estimationPrice ? estimationPrice.min : null,
        price_to: estimationPrice ? estimationPrice.max : null,
        widget: 'default',
        domain: new URLSearchParams(window.location.search).get('domain')
          ? new URLSearchParams(window.location.search).get('domain')
          : window.location.origin,
        referrer: 'as24',
      };

      const { submission_id, estimation_price } = await onMakeSubmission(data);

      if (!submission_id) {
        throw new Error('No submission_id');
      }

      let domain = '';

      if (data.brand && data.brand.customized_upload_area_domain) {
        domain = data.brand.customized_upload_area_domain;

        if (domain.slice(-1) === '/') {
          domain = domain.slice(0, -1);
        }
      } else {
        domain = process.env.REACT_APP_ULOAD_AREA_DOMAIN!;
      }

      setEstimatedPrice(estimation_price);
      setSubmissionLink(`${domain}/u/${submission_id}?lang=${locale}`);
      setStep(Step.FinalView);
      trackEvent('as24-step2-price-valuation');

      if (IS_PRODUCTION) {
        Refiner('identifyUser', {
          id: submission_id,
          email,
          name: `${firstName} ${lastName}`,
        });
      }
    } catch (err: any) {
      alert(
        'Unfortunately something went wrong! Please try again later or contact us.'
      );
    }
  };

  const onChangeResultPane = (
    articleKey: UserInput['articleKey'],
    allVehicles?: VehicleResults['all'],
    silentUpdate = false
  ) => {
    trackEvent('as24-step1-vehicle-selection');
    silentUpdate
      ? setUserInput((prev) => ({ ...prev, articleKey }))
      : handleChangeUserInput('articleKey', articleKey);
    setSelectedVehicle(
      (allVehicles ? allVehicles : vehicleResults.all).find(
        (el) => el.articleKey === articleKey
      ) || null
    );

    setStep(Step.Mileage);
    onFocusNextField(Step.Mileage);
  };

  const modalElement = createPortal(
    <AnimatePresence>
      {modal.isOpen && (
        <Fade>
          <Modal
            {...modal}
            onDismiss={() => setModal((prev) => ({ ...prev, isOpen: false }))}
          />
        </Fade>
      )}
    </AnimatePresence>,
    document.body
  );

  if (step >= Step.FinalView && userInput && selectedVehicle) {
    return (
      <>
        {modalElement}
        <Fade>
          <FinalView
            vehicle={selectedVehicle}
            attributes={attributes}
            estimationPrice={estimationPrice}
            onEdit={() => setStep(Step.Phone)}
            showEstimationPriceDescModal={() =>
              setModal({
                ...ESTIMATION_PRICE_DESC_MODAL(t),
                onConfirm: () =>
                  setModal((prev) => ({ ...prev, isOpen: false })),
              })
            }
            showNoEstimationModal={() =>
              setModal({
                ...NO_ESTIMATION_MODAL(t),
                onConfirm: () =>
                  setModal((prev) => ({ ...prev, isOpen: false })),
              })
            }
            submissionLink={submissionLink || ''}
          />
        </Fade>
      </>
    );
  }

  return (
    <>
      {modalElement}
      {createPortal(
        <AnimatePresence>
          {(isLoadingModels ||
            isLoadingVehicles ||
            isLoadingGetVehicleByLicensePlate ||
            isLoadingSendOtpCode ||
            isLoadingVerifyPhoneNumber ||
            isLoadingMakeSubmission ||
            (isLoadingAttributes && step >= Step.BodyType)) && (
            <Fade>
              <Loading />
            </Fade>
          )}
        </AnimatePresence>,
        document.body
      )}
      <div className="px-6">
        <Headline
          className="mb-6"
          content={t('directsaleTextStrings', 'search_title')}
        />
        <SwitchButton
          className="mb-6"
          activeSide={
            mode === Mode.LicensePlate ? ActiveSide.Left : ActiveSide.Right
          }
          leftText={t(
            'generalTextStrings',
            'search_type_option_license_plate_number'
          )}
          rightText={t('generalTextStrings', 'search_type_option_brand_model')}
          onLeftSwitch={() => setMode(Mode.LicensePlate)}
          onRightSwitch={() => setMode(Mode.Brand)}
        />

        {mode === Mode.Brand ? (
          <>
            <Fade className={selectCls}>
              <Select
                label={t('formFields', 'make', 'label')}
                options={makes}
                value={make}
                onChange={(make) => {
                  handleChangeUserInput('make', make);
                  setStep(Step.Model);

                  if (queryClient.getQueryData(['models', make])) {
                    onFocusNextField(Step.Model);
                  }
                }}
                placeholder={t('formFields', 'make', 'placeholder')}
                hint={t('formFields', 'make', 'hint')}
                tooltip={t('formFields', 'make', 'tooltip')}
              />
            </Fade>

            {step >= Step.Model && (
              <Fade className={selectCls}>
                <Select
                  ref={modelRef}
                  label={t('formFields', 'model', 'label')}
                  options={models}
                  value={model}
                  onChange={(model) => {
                    handleChangeUserInput('model', model);
                    setStep(Step.Year);
                  }}
                  placeholder={t('formFields', 'model', 'placeholder')}
                  hint={t('formFields', 'model', 'hint')}
                  tooltip={t('formFields', 'model', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Year && (
              <Fade className={selectCls}>
                <Select
                  ref={yearRef}
                  label={t('formFields', 'first_registration_year', 'label')}
                  options={yearOptions}
                  value={year}
                  onChange={(year) => {
                    handleChangeUserInput('year', year);
                    onGetVehicles({ make, model, year: year as number });
                  }}
                  placeholder={t(
                    'formFields',
                    'first_registration_year',
                    'placeholder'
                  )}
                  hint={t('formFields', 'first_registration_year', 'hint')}
                  tooltip={t(
                    'formFields',
                    'first_registration_year',
                    'tooltip'
                  )}
                />
              </Fade>
            )}

            {step >= Step.BodyType && (
              <Fade className={selectCls}>
                <Select
                  ref={bodyTypeRef}
                  label={t('formFields', 'body', 'label')}
                  options={getOptionsFromAttributes(
                    'bodyType',
                    vehicleResults.bodyTypes,
                    locale,
                    attributes
                  )}
                  value={bodyType}
                  onChange={(bodyType) => {
                    handleChangeUserInput('bodyType', bodyType);
                    setStep(Step.Fuel);
                  }}
                  placeholder={t('formFields', 'body', 'placeholder')}
                  hint={t('formFields', 'body', 'hint')}
                  tooltip={t('formFields', 'body', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Fuel && (
              <Fade className={selectCls}>
                <Select
                  ref={fuelRef}
                  label={t('formFields', 'fuel', 'label')}
                  options={getOptionsFromAttributes(
                    'fuel',
                    getRelevantOptions('fuel', vehicleResults.all, {
                      bodyType,
                    }),
                    locale,
                    attributes
                  )}
                  value={fuel}
                  onChange={(fuel) => {
                    handleChangeUserInput('fuel', fuel);
                    setStep(Step.Gear);
                  }}
                  placeholder={t('formFields', 'fuel', 'placeholder')}
                  hint={t('formFields', 'fuel', 'hint')}
                  tooltip={t('formFields', 'fuel', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Gear && (
              <Fade className={selectCls}>
                <Select
                  ref={gearRef}
                  label={t('formFields', 'gear', 'label')}
                  options={getOptionsFromAttributes(
                    'gear',
                    getRelevantOptions('gear', vehicleResults.all, {
                      bodyType,
                      fuel,
                    }),
                    locale,
                    attributes
                  )}
                  value={gear}
                  onChange={(gear) => {
                    handleChangeUserInput('gear', gear);
                    setStep(Step.Type);
                  }}
                  placeholder={t('formFields', 'gear', 'placeholder')}
                  hint={t('formFields', 'gear', 'hint')}
                  tooltip={t('formFields', 'gear', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Type && (
              <Fade className={cx(selectCls, { '!mb-6': step > Step.Type })}>
                <Select
                  ref={typeRef}
                  label={t('formFields', 'exact_model', 'label')}
                  options={getOptionsFromAttributes(
                    'type',
                    getRelevantOptions('type', vehicleResults.all, {
                      bodyType,
                      fuel,
                      gear,
                    }),
                    locale,
                    attributes
                  )}
                  value={type}
                  onChange={(type) => {
                    handleChangeUserInput('type', type);

                    const relevantVehicles = formatRelevantVehicles(
                      vehicleResults.all,
                      { bodyType, fuel, gear, type },
                      locale,
                      attributes,
                      t
                    );

                    if (relevantVehicles.length === 1) {
                      onChangeResultPane(
                        relevantVehicles[0].articleKey,
                        vehicleResults.all,
                        true
                      );
                    } else {
                      setStep(Step.ArticleKey);
                    }
                  }}
                  placeholder={t('formFields', 'exact_model', 'placeholder')}
                  hint={t('formFields', 'exact_model', 'hint')}
                  tooltip={t('formFields', 'exact_model', 'tooltip')}
                />
              </Fade>
            )}
          </>
        ) : (
          <Fade className="mb-6">
            <Input
              label={t('formFields', 'license_plate_number', 'label')}
              hint={t('formFields', 'license_plate_number', 'hint')}
              tooltip={t('formFields', 'license_plate_number', 'tooltip')}
              placeholder={t(
                'formFields',
                'license_plate_number',
                'placeholder'
              )}
              type={getFormFieldType('license_plate_number')}
              maxLength={8}
              validator={(licensePlate) =>
                /^[A-Za-z]{2}\d{5,6}$/.test(licensePlate as string)
              }
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.LicensePlate}
              onChange={() =>
                step !== Step.LicensePlate ? setStep(Step.LicensePlate) : {}
              }
              onClick={(licensePlate) =>
                onGetVehicleByLicensePlate(licensePlate as string)
              }
            />
          </Fade>
        )}
      </div>

      {step >= Step.ArticleKey && (
        <div ref={articleKeyRef}>
          <ResultPane
            value={articleKey}
            options={formatRelevantVehicles(
              vehicleResults.all,
              { bodyType, fuel, gear, type },
              locale,
              attributes,
              t
            )}
            onChange={onChangeResultPane}
          />
        </div>
      )}

      <div className="mt-12 px-6">
        {step >= Step.Mileage && (
          <Fade className="mb-6">
            <Headline
              className="mb-5"
              content={t('directsaleTextStrings', 'condition_title')}
            />
            <Input
              ref={mileageRef}
              label={t('formFields', 'mileage', 'label')}
              hint={t('formFields', 'mileage', 'hint')}
              tooltip={t('formFields', 'mileage', 'tooltip')}
              placeholder={t('formFields', 'mileage', 'placeholder')}
              type={getFormFieldType('mileage')}
              maxLength={8}
              initialValue={mileage}
              validator={(mileage) => Number.isFinite(mileage)}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.Mileage}
              onChange={() =>
                step !== Step.Mileage ? setStep(Step.Mileage) : {}
              }
              onClick={(mileage) => {
                handleChangeUserInput('mileage', mileage);
                setStep(Step.Condition);
              }}
            />
          </Fade>
        )}

        {step >= Step.Condition && (
          <Fade className={selectCls}>
            <Select
              ref={conditionRef}
              label={t('formFields', 'condition', 'label')}
              options={getGeneralOptions('condition_options_')}
              value={condition}
              onChange={(condition) => {
                handleChangeUserInput('condition', condition);
                setStep(Step.Equipment);
              }}
              placeholder={t('formFields', 'condition', 'placeholder')}
              hint={t('formFields', 'condition', 'hint')}
              tooltip={t('formFields', 'condition', 'tooltip')}
            />
          </Fade>
        )}

        {step >= Step.Equipment && (
          <Fade className={selectCls}>
            <Select
              ref={equipmentRef}
              label={t('formFields', 'equipment', 'label')}
              options={getGeneralOptions('equipment_options_')}
              value={equipment}
              onChange={(equipment) => {
                handleChangeUserInput('equipment', equipment);
                setStep(Step.Email);
              }}
              placeholder={t('formFields', 'equipment', 'placeholder')}
              hint={t('formFields', 'equipment', 'hint')}
              tooltip={t('formFields', 'equipment', 'tooltip')}
            />
          </Fade>
        )}

        {step >= Step.Email && (
          <Fade className="mb-6">
            <Headline
              className="mb-5"
              content={t('directsaleTextStrings', 'offer_form_title')}
            />
            <Input
              ref={emailRef}
              label={t('formFields', 'email', 'label')}
              hint={t('formFields', 'email', 'hint')}
              tooltip={t('formFields', 'email', 'tooltip')}
              placeholder={t('formFields', 'email', 'placeholder')}
              type={getFormFieldType('email')}
              initialValue={email}
              validator={(email) => validator.isEmail(email as string)}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              btnHint={parse(t('generalTextStrings', 'submission_legal_hint'))}
              showBtn={step === Step.Email}
              onChange={() => (step !== Step.Email ? setStep(Step.Email) : {})}
              onClick={(email) => {
                handleChangeUserInput('email', email);
                setStep(Step.FirstName);
              }}
            />
          </Fade>
        )}

        {step >= Step.FirstName && (
          <Fade className="mb-6">
            <Input
              ref={firstNameRef}
              label={t('formFields', 'first_name', 'label')}
              hint={t('formFields', 'first_name', 'hint')}
              tooltip={t('formFields', 'first_name', 'tooltip')}
              placeholder={t('formFields', 'first_name', 'placeholder')}
              type={getFormFieldType('first_name')}
              initialValue={firstName}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.FirstName}
              onChange={() =>
                step !== Step.FirstName ? setStep(Step.FirstName) : {}
              }
              onClick={(firstName) => {
                handleChangeUserInput('firstName', firstName);
                setStep(Step.LastName);
              }}
            />
          </Fade>
        )}

        {step >= Step.LastName && (
          <Fade className="mb-6">
            <Input
              ref={lastNameRef}
              label={t('formFields', 'last_name', 'label')}
              hint={t('formFields', 'last_name', 'hint')}
              tooltip={t('formFields', 'last_name', 'tooltip')}
              placeholder={t('formFields', 'last_name', 'placeholder')}
              type={getFormFieldType('last_name')}
              initialValue={lastName}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.LastName}
              onChange={() =>
                step !== Step.LastName ? setStep(Step.LastName) : {}
              }
              onClick={(lastName) => {
                handleChangeUserInput('lastName', lastName);
                setStep(Step.Phone);
              }}
            />
          </Fade>
        )}

        {step >= Step.Phone && (
          <Fade className="mb-6">
            <Input
              ref={phoneRef}
              label={t('formFields', 'phone', 'label')}
              hint={t('formFields', 'phone', 'hint')}
              tooltip={t('formFields', 'phone', 'tooltip')}
              placeholder={t('formFields', 'phone', 'placeholder')}
              type={getFormFieldType('phone')}
              initialValue={phone}
              validator={(phone) =>
                validator.isMobilePhone(
                  formatPhoneNumber(phone.toString()),
                  'any',
                  {
                    strictMode: true,
                  }
                )
              }
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.Phone}
              onChange={() => (step !== Step.Phone ? setStep(Step.Phone) : {})}
              onClick={(val) => {
                const userPhone = formatPhoneNumber(val.toString());
                handleChangeUserInput('phone', userPhone);

                if (cmsData?.enable2fa) {
                  onSendOtpCode(userPhone);
                } else {
                  onSubmit({ userPhone });
                }
              }}
            />
          </Fade>
        )}

        {step >= Step.PhoneVerification && (
          <Fade className="mb-6">
            <PhoneVerification
              ref={otpCodeRef}
              verifyPhoneNumber={(code) => onVerifyPhoneNumber(code)}
              resendOtpCode={() => onSendOtpCode(userInput.phone)}
              onSuccess={(token) => {
                handleChangeUserInput('token', token);
                onSubmit({ verificationToken: token as string });
              }}
            />
          </Fade>
        )}
      </div>
    </>
  );
};

export default Main;
