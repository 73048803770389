import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import parse from 'html-react-parser';
import cx from 'classnames';

type Props = {
  className?: string;
  options: { title: string; description: string; articleKey: number }[];
  value: number | null;
  onChange: (val: number) => any;
};

const ResultPane: React.FC<Props> = ({
  className,
  options,
  value,
  onChange,
}) => {
  return (
    <RadioGroup className={className} value={value} onChange={onChange}>
      {options.map((option) => (
        <Field
          key={option.articleKey}
          className={cx(
            'flex items-start gap-2 p-6 transition-colors',
            value === option.articleKey && 'bg-highlight'
          )}
        >
          <Radio
            value={option.articleKey}
            className="mt-1 group flex flex-shrink-0 size-4 items-center justify-center rounded-full border bg-background data-[checked]:bg-dark outline-none"
          >
            <span className="invisible size-1.5 rounded-full bg-background group-data-[checked]:visible" />
          </Radio>
          <Label className="cursor-pointer">
            <h3 className="font-bold">{option.title}</h3>
            <p>{parse(option.description)}</p>
          </Label>
        </Field>
      ))}
    </RadioGroup>
  );
};

export default ResultPane;
