import { useCmsData } from 'contexts/CmsDataProvider';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = {
  hint: string;
  onHintClick: () => any;
};

const CreateWithoutEstimate: React.FC<Props> = ({ hint, onHintClick }) => {
  const { t } = useCmsData();

  return (
    <div className="p-6 rounded-2xl border border-highlight bg-highlight-tint">
      <div className="mb-2.5 text-md font-bold leading-[24px]">
        {t('directsaleTextStrings', 'final_view_no_estimation')}
      </div>
      <div
        className="mt-3 flex text-tertiary text-sm cursor-pointer"
        onClick={onHintClick}
      >
        <InfoIcon className="shrink-0 size-6 mr-2" />
        {hint}
      </div>
    </div>
  );
};

export default CreateWithoutEstimate;
