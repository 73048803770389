import { Tooltip as TooltipComponent } from 'antd';
import cx from 'classnames';

type Props = {
  title: string;
  className?: string;
  children: React.ReactNode;
};

const Tooltip: React.FC<Props> = ({ title, className, children }) => {
  return (
    <TooltipComponent
      className={cx('cursor-pointer', className)}
      title={title}
      zIndex={10}
    >
      {children}
    </TooltipComponent>
  );
};

export default Tooltip;
