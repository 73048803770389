import cx from 'classnames';

type Props = {
  content: string;
  className?: string;
};

const Headline: React.FC<Props> = ({ content, className }) => {
  return <h2 className={cx('text-2xl font-bold', className)}>{content}</h2>;
};

export default Headline;
