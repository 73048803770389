import { forwardRef, useEffect, useState } from 'react';
import cx from 'classnames';

import Headline from 'components/ui/Headline';
import Input from 'components/ui/Input';

import { useCmsData } from 'contexts/CmsDataProvider';

type Props = {
  onSuccess: (token: string) => any;
  resendOtpCode: () => any;
  verifyPhoneNumber: (code: string) => any;
};

const PhoneVerification = forwardRef<HTMLInputElement, Props>(
  ({ onSuccess, resendOtpCode, verifyPhoneNumber }, ref) => {
    const [optCode, setOtpCode] = useState('');
    const [invalid, setInvalid] = useState(false);

    const [isCooldown, setIsCooldown] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(60);

    const { t, getFormFieldType } = useCmsData();

    useEffect(() => {
      let timer: NodeJS.Timeout;

      if (isCooldown) {
        timer = setInterval(() => {
          setSecondsLeft((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              setIsCooldown(false);
              return 60;
            }
            return prev - 1;
          });
        }, 1000);
      }
      return () => clearInterval(timer);
    }, [isCooldown]);

    const onRetry = async () => {
      await resendOtpCode();
      setIsCooldown(true);
    };

    const onVerifyPhoneNumber = async () => {
      try {
        const { token } = await verifyPhoneNumber(optCode);
        onSuccess(token);
      } catch {
        setInvalid(true);
      }
    };

    const retryBlock = (
      <div className={cx(invalid ? 'mt-0' : 'mt-4')}>
        {t('generalTextStrings', '2fa_not_received_hint')}{' '}
        {isCooldown ? (
          <span className="text-border">
            {t('generalTextStrings', '2fa_resend_in').replace(
              '{countdown}',
              secondsLeft.toString()
            )}
          </span>
        ) : (
          <span
            className="text-dark underline cursor-pointer"
            onClick={onRetry}
          >
            {t('generalTextStrings', '2fa_resend_btn')}
          </span>
        )}
      </div>
    );

    return (
      <>
        <Headline
          className="mb-5"
          content={t('generalTextStrings', '2fa_title')}
        />
        <Input
          ref={ref}
          label={t('formFields', 'otp_code', 'label')}
          hint={t('formFields', 'otp_code', 'hint')}
          placeholder={t('formFields', 'otp_code', 'placeholder')}
          type={getFormFieldType('otp_code')}
          btnText={t('generalTextStrings', 'input_text_send_button')}
          onChange={(otpCode) => {
            setOtpCode(otpCode as string);
            setInvalid(false);
          }}
          onClick={onVerifyPhoneNumber}
          showBtn
          invalid={invalid}
          invalidMsg={t('generalTextStrings', '2fa_invalid_otp_code')}
          extraContent={retryBlock}
        />
      </>
    );
  }
);

export default PhoneVerification;
