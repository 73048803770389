import { useMemo, useState } from 'react';

import Headline from 'components/ui/Headline';
import PriceRange from 'components/PriceRange';
import Button from 'components/ui/Button';
import CreateWithoutEstimate from './CreateWithoutEstimate';

import { useCmsData } from 'contexts/CmsDataProvider';
import { getOptionsFromAttributes } from 'utils/utils';

import type { VehicleResults } from 'types/types';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

type Props = {
  vehicle: VehicleResults['all'][number];
  attributes: any;
  submissionLink: string;
  estimationPrice: { min: number; max: number } | null;
  onEdit: () => any;
  showEstimationPriceDescModal: () => any;
  showNoEstimationModal: () => any;
};

enum View {
  EstimationPrice,
  NextSteps,
}

const thumbnails = [
  'front',
  'back',
  'side_left',
  'side_right',
  'obliquely',
  'interior_front',
  'interior',
  'speedometer',
  'trip_computer_engine_on',
];

const FinalView: React.FC<Props> = ({
  vehicle,
  attributes,
  estimationPrice,
  onEdit,
  showEstimationPriceDescModal,
  showNoEstimationModal,
  submissionLink,
}) => {
  const [view, setView] = useState<View>(View.EstimationPrice);

  const { data: cmsData, t, locale } = useCmsData();

  const { brand, model, type, bodyType } = vehicle;

  const advantages: string[] = useMemo(
    () =>
      cmsData?.directsaleTextStrings
        .filter(
          (el: any) => el.key === 'final_view_estimation_price_advantages_item'
        )
        .map((el: any) => el[locale]),
    [cmsData, locale]
  );
  const estimationNextSteps: string[] = useMemo(
    () =>
      cmsData?.directsaleTextStrings
        .filter(
          (el: any) => el.key === 'final_view_estimation_price_next_steps_item'
        )
        .map((el: any) => el[locale]),
    [cmsData, locale]
  );

  if (view === View.NextSteps) {
    return (
      <div className="px-6">
        <Headline
          className="mb-6"
          content={t('directsaleTextStrings', 'final_view_next_steps_title')}
        />
        <div>
          <div className="flex items-start mb-3">
            <div className="mr-2 flex items-center justify-center text-sm font-bold size-6 rounded-full bg-primary">
              1
            </div>
            {t('directsaleTextStrings', 'final_view_next_steps_1')}
          </div>
          <div className="flex items-start mb-4">
            <div className="mr-2 flex items-center justify-center text-sm font-bold size-6 rounded-full bg-primary">
              2
            </div>
            {t('directsaleTextStrings', 'final_view_next_steps_2')}
          </div>

          <div className="grid grid-cols-3 gap-x-3 gap-y-4">
            {thumbnails.map((slug) => (
              <div key={slug}>
                <img
                  className="border border-light rounded w-full h-[72px] object-cover"
                  src={require(`../assets/thumbnails/${slug}.jpg`)}
                  alt="vehicle"
                />
                <div className="mt-1 text-xs text-center">
                  {t(
                    'directsaleTextStrings',
                    `final_view_next_steps_thumb_${slug}`
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <Button
          href={submissionLink}
          className="mt-6 w-full"
          label={t(
            'directsaleTextStrings',
            'final_view_next_steps_to_upload_area_btn'
          )}
        />

        <p className="mt-4">
          {t('directsaleTextStrings', 'final_view_next_steps_hint')}
        </p>
      </div>
    );
  }

  return (
    <div className="px-6">
      <Headline
        className="mb-6"
        content={t(
          'directsaleTextStrings',
          estimationPrice
            ? 'final_view_estimation_title'
            : 'final_view_no_estimation_title'
        )}
      />

      <div className="flex justify-between">
        <h3 className="truncate">
          {brand} {model} {type}{' '}
          {getOptionsFromAttributes('bodyType', bodyType, locale, attributes)}
        </h3>
        <span
          className="ml-3 text-dark underline cursor-pointer"
          onClick={onEdit}
        >
          {t('directsaleTextStrings', 'final_view_estimation_edit_vehicle_btn')}
        </span>
      </div>

      <div className="mt-6">
        {estimationPrice ? (
          <PriceRange
            min={estimationPrice.min}
            max={estimationPrice.max}
            hint={t(
              'directsaleTextStrings',
              'final_view_estimation_price_range_hint'
            )}
            onHintClick={showEstimationPriceDescModal}
          />
        ) : (
          <CreateWithoutEstimate
            hint={t('directsaleTextStrings', 'final_view_no_estimation_hint')}
            onHintClick={showNoEstimationModal}
          />
        )}

        <Button
          className="mt-6 w-full"
          label={t('directsaleTextStrings', 'success_button')}
          onClick={() => setView(View.NextSteps)}
        />

        <Headline
          className="mt-12"
          content={t(
            'directsaleTextStrings',
            'final_view_estimation_price_advantages_title'
          )}
        />
        <div className="mt-4">
          {advantages.map((advantage, i) => (
            <div key={i} className="flex items-start mb-3 last:mb-0">
              <CheckIcon className="mr-2 size-6 text-success" />
              {advantage}
            </div>
          ))}
        </div>
        <hr className="my-8 text-light" />
        <Headline
          className="mt-12"
          content={t(
            'directsaleTextStrings',
            'final_view_estimation_price_next_steps_title'
          )}
        />
        <div className="mt-4">
          {estimationNextSteps.map((nextStep, i) => (
            <div key={i} className="flex items-start mb-3 last:mb-0">
              <div className="mr-2 flex items-center justify-center text-sm font-bold size-6 rounded-full bg-primary">
                {i + 1}
              </div>
              {nextStep}
            </div>
          ))}
        </div>

        <Button
          className="mt-6 w-full"
          label={t('directsaleTextStrings', 'success_button')}
          onClick={() => setView(View.NextSteps)}
        />
      </div>
    </div>
  );
};

export default FinalView;
