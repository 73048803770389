import { motion } from 'framer-motion';

type Props = {
  className?: string;
  exit?: boolean;
  children: React.ReactNode;
};

const Fade: React.FC<Props> = ({ className = '', children, exit = true }) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={exit ? { opacity: 0 } : undefined}
    >
      {children}
    </motion.div>
  );
};

export default Fade;
