import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Refiner from 'refiner-js';

import Header from 'components/Header';
import StatusLine, { Stage } from 'components/StatusLine';
import Loading from 'components/Loading/Loading';
import Fade from 'components/ui/Fade';
import Main from 'components/Main';

import { useCmsData } from 'contexts/CmsDataProvider';
import { IS_PRODUCTION } from 'constants/constants';

const DISMISS_FORM_ID = '5767a850-4e73-11ef-a399-25ffbccf0c19';
const containerCls =
  'relative w-full max-w-[374px] pb-20 bg-background h-screen drop-shadow-3 overflow-y-auto';

const App: React.FC = () => {
  const [stage, setStage] = useState<Stage>(Stage.Search);
  const { t, locale, isLoading: isLoadingCmsData } = useCmsData();

  useEffect(() => {
    if (IS_PRODUCTION) {
      Refiner('setProject', '752daab0-4e52-11ef-845f-3d210da4bb4c');
      Refiner('setLocale', locale);

      Refiner('onComplete', (formId: string) => {
        if (formId === DISMISS_FORM_ID) {
          onWidgetClose(true);
        }
      });
      Refiner(
        'onRequestResponse',
        (status: number, action: string, data: any) => {
          if (data.reason === 'already_viewed') {
            onWidgetClose(true);
          }
        }
      );
    }
  }, [locale]); // eslint-disable-line

  const onWidgetClose = (forceClose = false) => {
    if (IS_PRODUCTION && stage !== Stage.Completed && !forceClose) {
      return Refiner('showForm', DISMISS_FORM_ID);
    }

    window.parent.postMessage(
      {
        type: 'close',
      },
      '*'
    );
  };

  if (isLoadingCmsData) {
    return (
      <div className={containerCls}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <html lang={locale} />
        <title>{t('generalTextStrings', 'header_title')}</title>
      </Helmet>
      <Fade exit={false}>
        <div id="container" className={containerCls}>
          <Header
            content={t('generalTextStrings', 'header_title')}
            stage={stage}
            onClose={() => onWidgetClose(false)}
          />
          <div className="mt-6 px-6">
            <StatusLine className="mb-[80px]" stage={stage} />
          </div>

          <Main onSetStage={(stage: Stage) => setStage(stage)} />
        </div>
      </Fade>
    </>
  );
};

export default App;
